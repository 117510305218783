var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.addModelShow
        ? _c(
            "el-row",
            { staticStyle: { border: "1px solid #eee", padding: "10px 20px" } },
            [
              _c(
                "el-col",
                {
                  attrs: {
                    span: 3,
                    width:
                      "border-right:1px solid #eee;display:flex;align-items: center;justify-content:center;",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "blockBtnWrap" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "imageBox",
                          style: _vm.isJoin
                            ? "background:rgb(189, 49, 36)"
                            : "background:rgb(129, 179, 55)",
                        },
                        [
                          _c("el-image", {
                            staticStyle: { width: "40px", height: "40px" },
                            attrs: {
                              src: _vm.isJoin
                                ? require("@/assets/images/block_join.svg")
                                : require("@/assets/images/block_remove.svg"),
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          on: { change: _vm.changeBlockBtn },
                          model: {
                            value: _vm.isJoin,
                            callback: function ($$v) {
                              _vm.isJoin = $$v
                            },
                            expression: "isJoin",
                          },
                        },
                        _vm._l(_vm.blockSelectOptions, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                      _c("div", [_vm._v(_vm._s(_vm.nowTime))]),
                    ],
                    1
                  ),
                ]
              ),
              _c("el-col", { attrs: { span: 21 } }, [
                _c(
                  "div",
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "text-align": "right",
                          "margin-bottom": "10px",
                          "margin-right": "5px",
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.dialogLoading,
                                expression: "dialogLoading",
                              },
                            ],
                            attrs: { type: "primary", size: "mini" },
                            on: { click: _vm.addSubmitBtn },
                          },
                          [_vm._v("保存")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: { click: _vm.addCancelBtn },
                          },
                          [_vm._v("取消")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "avue-form",
                      {
                        ref: "addForm",
                        staticClass: "remove-avue-form-menu",
                        attrs: { option: _vm.addOptions },
                        on: { submit: _vm.addSubmit },
                        model: {
                          value: _vm.addForm,
                          callback: function ($$v) {
                            _vm.addForm = $$v
                          },
                          expression: "addForm",
                        },
                      },
                      [
                        _c(
                          "template",
                          { slot: "prjCode" },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请选择" },
                              on: { focus: _vm.clickOpenProjectSelection },
                              model: {
                                value: _vm.addForm.prjCode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addForm, "prjCode", $$v)
                                },
                                expression: "addForm.prjCode",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "template",
                          { slot: "companyName" },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请选择" },
                              on: { focus: _vm.clickOpenCompanySelection },
                              model: {
                                value: _vm.addForm.companyName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addForm, "companyName", $$v)
                                },
                                expression: "addForm.companyName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                    _c("uploader", {
                      ref: "uploader",
                      attrs: {
                        fileSize: 20,
                        accept:
                          ".jpg,.png,.pdf,.gif,.jpeg,.ppt,.pptx,.doc,.docx,.xls,.xlsx",
                        uploadLimit: 50,
                        multiple: true,
                        fileOptions: _vm.fileOptions,
                        gridRowBtn: _vm.fileGridRowBtn,
                      },
                      on: { getTableData: _vm.getTableData },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._l(_vm.detailList, function (item, index) {
        return _c(
          "el-row",
          {
            key: index,
            staticStyle: { border: "1px solid #eee", padding: "10px 20px" },
          },
          [
            _c(
              "el-col",
              {
                attrs: {
                  span: 3,
                  width:
                    "border-right:1px solid #eee;display:flex;align-items: center;justify-content:center;",
                },
              },
              [
                _c("div", { staticClass: "blockBtnWrap" }, [
                  _c(
                    "div",
                    {
                      staticClass: "imageBox",
                      style:
                        item.blState == 1
                          ? "background:rgb(189, 49, 36)"
                          : "background:rgb(129, 179, 55)",
                    },
                    [
                      _c("el-image", {
                        staticStyle: { width: "40px", height: "40px" },
                        attrs: {
                          src:
                            item.blState == 1
                              ? require("@/assets/images/block_join.svg")
                              : require("@/assets/images/block_remove.svg"),
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticStyle: { "line-height": "38px" } }, [
                    _vm._v(
                      _vm._s(item.blState == 1 ? "加入黑名单" : "解除黑名单")
                    ),
                  ]),
                  _c("div", [_vm._v(_vm._s(item.updateTime))]),
                ]),
              ]
            ),
            _c("el-col", { attrs: { span: 21 } }, [
              _c(
                "div",
                [
                  _vm.formType != "view"
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            "text-align": "right",
                            "margin-bottom": "10px",
                            "margin-right": "5px",
                          },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "mini" },
                              on: {
                                click: function ($event) {
                                  return _vm.itemSaveBtn(
                                    "itemRef" + index,
                                    item,
                                    index
                                  )
                                },
                              },
                            },
                            [_vm._v("保存")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("avue-form", {
                    ref: "itemRef" + index,
                    refInFor: true,
                    staticClass: "remove-avue-form-menu",
                    attrs: {
                      option:
                        item.blState == 1 ? _vm.joinOptions : _vm.removeOptions,
                    },
                    model: {
                      value: _vm.detailList[index],
                      callback: function ($$v) {
                        _vm.$set(_vm.detailList, index, $$v)
                      },
                      expression: "detailList[index]",
                    },
                  }),
                  _c("uploader", {
                    ref: "uploaderItem" + index,
                    refInFor: true,
                    attrs: {
                      tableFiles: _vm.changeTable(item.files, index),
                      fileSize: 20,
                      accept:
                        ".jpg,.png,.pdf,.gif,.jpeg,.ppt,.pptx,.doc,.docx,.xls,.xlsx",
                      uploadLimit: 50,
                      multiple: true,
                      fileOptions: _vm.fileOptions,
                      gridRowBtn: _vm.fileGridRowBtn,
                      disabled: _vm.formType == "view",
                    },
                    on: {
                      getTableData: (data) =>
                        _vm.getItemTableData(
                          data,
                          "itemRef" + index,
                          item,
                          index
                        ),
                    },
                  }),
                ],
                1
              ),
            ]),
          ],
          1
        )
      }),
      _c("selectProjectDialogVue", {
        ref: "selectProjectDialog",
        attrs: { phone: _vm.phone },
        on: { changeProject: _vm.changeProject },
      }),
      _c("blackCompanyDialogVue", {
        ref: "blackCompanyDialog",
        attrs: { isPerson: true },
        on: { changeCompany: _vm.changeCompany },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }