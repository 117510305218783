var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { height: "100%", "background-color": "#ffffff" } },
    [
      _c("head-layout", {
        attrs: {
          "head-title": _vm.headTitle,
          "head-btn-options": _vm.headBtnOptions,
        },
        on: { "head-cancel": _vm.headCancel },
      }),
      _c("div", { staticClass: "formContentBox" }, [
        _c(
          "div",
          { staticClass: "formMain" },
          [
            _c(
              "el-form",
              {
                ref: "dataForm",
                attrs: {
                  model: _vm.dataForm,
                  "label-width": "150px",
                  disabled: _vm.formType == "view",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "formTopic",
                    staticStyle: { "margin-bottom": "12px" },
                  },
                  [_c("span", [_vm._v("个人信息")])]
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "姓名", prop: "userName" } },
                          [
                            _c("el-input", {
                              attrs: {
                                disabled: "",
                                placeholder: "请输入姓名",
                              },
                              model: {
                                value: _vm.dataForm.userName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "userName", $$v)
                                },
                                expression: "dataForm.userName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "身份证号", prop: "idCoding" } },
                          [
                            _c("el-input", {
                              attrs: {
                                disabled: "",
                                placeholder: "请输入身份证号",
                              },
                              model: {
                                value: _vm.dataForm.idCoding,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "idCoding", $$v)
                                },
                                expression: "dataForm.idCoding",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "性别", prop: "sex" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择", disabled: "" },
                                model: {
                                  value: _vm.dataForm.sex,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "sex", $$v)
                                  },
                                  expression: "dataForm.sex",
                                },
                              },
                              _vm._l(_vm.sexOptions, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "年龄", prop: "birthday" } },
                          [
                            _c("el-input", {
                              attrs: {
                                disabled: "",
                                placeholder: "请输入年龄",
                              },
                              model: {
                                value: _vm.dataForm.birthday,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "birthday", $$v)
                                },
                                expression: "dataForm.birthday",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "籍贯", prop: "domicile" } },
                          [
                            _c("el-input", {
                              attrs: {
                                disabled: "",
                                placeholder: "请输入籍贯",
                              },
                              model: {
                                value: _vm.dataForm.domicile,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "domicile", $$v)
                                },
                                expression: "dataForm.domicile",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "工种", prop: "post" } },
                          [
                            _c("el-input", {
                              attrs: {
                                disabled: "",
                                placeholder: "请输入工种",
                              },
                              model: {
                                value: _vm.dataForm.post,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "post", $$v)
                                },
                                expression: "dataForm.post",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "formTopic",
                staticStyle: { "margin-bottom": "12px" },
              },
              [
                _c("span", [_vm._v("详情记录")]),
                _vm.formType != "view"
                  ? _c(
                      "el-button",
                      { attrs: { size: "mini" }, on: { click: _vm.addBtn } },
                      [_vm._v("新增")]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c("addForm", {
              ref: "addForm",
              attrs: {
                formType: _vm.formType,
                userId: _vm.dataForm.id,
                phone: _vm.dataForm.phone,
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }