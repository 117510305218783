<template>
  <el-dialog
    :title="title"
    :modal-append-to-body="false"
    :close-on-click-modal="false"
    :visible.sync="showDialog"
    width="60%"
    class="qmDialog blackCompanyDialog"
    @close="closeDialog"
  >
    <grid-head-layout
      v-if="!isPerson"
      ref="gridHeadLayout"
      :search-columns="searchColumns"
      @grid-head-search="gridHeadSearch"
      @grid-head-empty="gridHeadEmpty"
    ></grid-head-layout>

    <grid-layout
      ref="gridLayOut"
      :table-options="tableOption"
      :table-data="tableData"
      :table-loading="tableLoading"
      :data-total="page.total"
      :page="page"
      @page-current-change="onLoad"
      @page-size-change="onLoad"
    >
      <template #customBtn="{ row }">
        <el-button
          style="margin: 0 3px"
          type="text"
          size="small"
          @click="rowSelect(row)"
          >选择
        </el-button>
      </template>
    </grid-layout>
  </el-dialog>
</template>

  <script>
import GridLayout from "@/views/components/layout/grid-layout";
import { getList } from "@/api/safe/thirdcompany";
import { prjthirdcompanyPage } from "@/api/safe/itemList";
import { fieldInfo } from "@/api/base/length";
export default {
  props:['isPerson'],
  components: {
    GridLayout,
  },
  data() {
    return {
      showDialog: false,
      title: "",
      searchColumns: [
        {
          label: "项目名称",
          prop: "companyCode",
          span: 6,
          placeholder: "公司名称或统一社会信用代码",
        },
        {
          label: "更新日期",
          prop: "curDate",
          span: 6,
          type: "date-format",
          placeholder: "请选择成立日期",
        },
      ],
      tableLoading: true,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      selectionList: [],
      tableOption: {
        selection: false,
        column: [
          {
            label: "统一社会信用代码",
            prop: "companyCode",
            align: fieldInfo.USCI.align,
            minWidth: fieldInfo.USCI.length,
            overHidden: true,
          },
          {
            label: "公司名称",
            prop: "companyName",
            align: fieldInfo.name_company.align,
            minWidth: fieldInfo.name_company.length,
            overHidden: true,
          },
          {
            label: "法定代表人",
            prop: "legalRep",
            align: fieldInfo.name_person.align,
            minWidth: fieldInfo.name_person.length,
            overHidden: true,
          },
          {
            label: "注册地",
            prop: "regLocation",
            align: fieldInfo.fullAddress.align,
            minWidth: fieldInfo.fullAddress.length,
            overHidden: true,
          },
          {
            label: "成立日期",
            prop: "curDate",
            align: fieldInfo.date.align,
            minWidth: fieldInfo.date.length,
            overHidden: true,
          },
        ],
      },
      tableData: [],
      submitBtn: false,
      emptyBtn: false,
    };
  },
  methods: {
    // 从黑名单人员中选择项目后选择企业 打开企业弹窗
    personInit(params){
      this.showDialog = true;
      this.title = "选择黑名单公司";
      this.tableLoading = true;

      // prjthirdcompanyPage(params).then((res) => {
      //   if(res.data.code == 200){
      //     this.tableData = res.data.data
      //   }else{
      //     this.tableData =  []
      //   }
      //   this.tableLoading = false;
      // })
      this.onLoad(this.page, params);
    },
    // 黑名单组织中打开企业
    init(type) {
      this.showDialog = true;
      this.title = "选择黑名单公司";
      this.onLoad(this.page, {});
    },
    // 搜索
    gridHeadSearch(searchForm) {
      this.page.currentPage = 1;
      this.searchForm = searchForm;
      this.onLoad(this.page, searchForm);
    },
    // 清空
    gridHeadEmpty(searchForm) {
      this.page.currentPage = 1;
      this.$refs.gridHeadLayout.searchForm = {};
      this.searchForm = {};
      this.onLoad(this.page, searchForm);
    },
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;
      getList(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.searchForm)
      ).then((res) => {
        const data = res.data.data;
        this.page.total = res.data.data.total;
        this.$refs.gridLayOut.page.total = data.total;
        this.tableData = data.records;
        this.tableLoading = false;
      });
    },
    // 选择公司
    rowSelect(row) {
      this.showDialog = false;
      if(this.isPerson){
        this.$emit('changeCompany',row)
      }else{
        this.$router.push({
          path: `/safe/blacklistOrganization/index/add`,
          query: {
            type: "add",
            companyRow:JSON.stringify(row)
          },
        });
      }

    },
    closeDialog() {
      if(!this.isPerson){
        this.$refs.gridHeadLayout.searchForm = {};
      }
      this.searchForm = {};
      this.showDialog = false;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep.blackCompanyDialog .avue-crud .el-table {
  height: 55vh !important;
  max-height: 55vh !important;
}
</style>
